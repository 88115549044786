<template>
  <div class="main-no-found">
    <img class="no-found" src="../../assets/images/no-found.png" alt="404-icon" title="404-icon" />
    <a href="/" class="backIndex">返回首页</a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import eventBus from "../../plugins/eventBus";
export default {
  name: "index",
  mixins: [],
  props: {},
  model: {},
  components: {},
  data() {
    return {
      timing: null
    };
  },
  computed: {
    ...mapGetters(["loginUid", "loginUser", "redPoint", "gameInfo"])
  },
  methods: {
    onShowLogin() {
      eventBus.$emit("onShowLogin");
    },
    timeChange() {
      this.timing = setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.timeChange();
  },
  updated() {},
  beforeDestroy() {
    clearTimeout(this.timing);
  },
  destroyed() {}
};
</script>

<style lang="less" scoped>
.main-no-found {
  min-height: 570px;
}
.no-found {
  display: block;
  width: 332px;
  margin: 136px auto 0;
}
.backIndex {
  display: block;
  width: 187px;
  height: 40px;
  background: #00afe8;
  border-radius: 4px;
  margin: 44px auto 0;
  font-size: 14px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  &:hover {
    opacity: 0.9;
  }
}
</style>
